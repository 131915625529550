
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
















































.tv-solution-table,
[class*='tv-solution-table--'] {
  @include bg-color;

  padding-top: $spacing * 1.5;
  padding-bottom: $spacing * 1.5;

  @include mq(m) {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }

  @include mq(xl) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.tv-solution-table__title {
  @extend %text-center;

  max-width: 75rem;
  margin: 0 auto;
}

.tv-solution-table__subtitle {
  @extend %text-center;

  margin-bottom: 0;
}

.tv-solution-table__switcher-outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tv-solution-table__switcher {
  @extend %fw-bold;

  ::v-deep .switcher__option {
    padding: 1.5rem 2rem;
  }
}

.tv-solution-table__item {
  flex: 1;
  margin: $spacing;
  padding: $spacing * 2;
  background-color: $white;
}

.tv-solution-table__item__icon {
  fill: $c-pink-medium;
}

.tv-solution-table__link-outer {
  position: absolute;
  right: 0;
}
