
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        























































































































































































.section-hero {
  position: relative;
  overflow: hidden;
  margin-top: $spacing * 5;

  @include mq($until: m) {
    [data-template='offers-packs'] & {
      margin-top: $spacing * 2.5;
    }
  }

  ::v-deep {
    .hero__price-box {
      position: relative;
      z-index: 99;
      overflow: visible;
      width: 100%;
      max-width: 45rem;
      margin-top: $spacing * 1.5;
      padding: 2rem;
      background-color: transparent;
      border: 1px solid $c-blue-lighter;
      border-radius: 1rem;
      box-shadow: none;
      // transform: translateY($spacing * 3);

      &.price-box,
      &[class*='price-box--'] {
        .price-box__misc {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .btn {
          margin-top: 0;
        }

        .price-box__prev {
          color: $white;

          &::after {
            background-color: $white;
          }
        }
      }
      .price-box__price {
        small:not(:last-of-type) {
          display: none;
        }
      }
    }
    .price-box__promo {
      position: absolute;
      top: 0;
      display: inline-block;
      width: auto;
      border-radius: 0.2rem;
      transform: translateY(-50%);
    }
  }

  @include mq(m) {
    min-height: 40rem;
  }

  @include mq(l) {
    min-height: 55rem;
    margin-top: $spacing * 6;
  }

  @include mq(xxl) {
    min-height: 70rem;
  }
}

.section-hero__inner {
  padding-bottom: get-spacing(xl, s);

  .net-modem & {
    .voo-app--be & {
      padding-top: 0;
    }
  }

  @include mq(m) {
    padding-bottom: get-spacing(xl, m);
  }

  @include mq(l) {
    padding-top: $spacing * 5;

    .net-modem & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .eda & {
      display: flex;
      justify-content: space-between;
    }

    .blog-archive & {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 33%;
        height: 100%;
        background: $c-pink-medium;
        clip-path: polygon(85% 0, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  @include mq(xl) {
    padding-bottom: get-spacing(xl, l);

    .blog-archive & {
      &::after {
        width: 40%;
        clip-path: polygon(80% 0, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  @include mq(xxl) {
    padding-top: $spacing * 10;

    .blog-archive & {
      &::after {
        width: 32%;
        clip-path: polygon(70% 0, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }
}

.section-hero__speedlines {
  left: 50%;

  @include mq($until: m) {
    position: relative;
    left: 0;
    max-width: 50rem;
    margin: 0 auto;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (1 / 1) * 100%;
    }
    [data-template='offers-packs'] & {
      display: none;
    }
  }

  @include mq(m) {
    width: col(6);
  }
}

.section-hero__visuals {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 30rem;
  padding: $spacing * 2 col(1) 0;

  .blog-archive & {
    position: absolute;
    right: 0;
    left: initial;
    width: 30%;
    height: 10rem;
  }

  @include mq(s) {
    .blog-archive & {
      height: 100%;
    }
  }

  @include mq(l) {
    position: absolute;
    top: 0;
    right: $wrapper-spacing-m;
    left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    max-width: 60rem;
    height: 100%;
    padding: 0;

    .blog-archive & {
      height: 100%;
    }
  }

  @include mq(xl) {
    right: 5rem;
    width: 38%;
  }
}

.section-hero__visuals__picture {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  text-align: center;

  .blog-archive & {
    display: none;
  }
}

.section-hero__visuals__speedlines {
  z-index: 1;
  left: 0;
  width: 100%;
  transform: scale(1.5);
}

.section-hero__picture {
  margin: 0 auto;

  @include mq(m) {
    position: absolute;
    z-index: -1;
    top: 0;
    right: col(-1);
    width: 60%;
    height: 100%;
  }
}

.section-hero__content {
  padding: $spacing * 3 $wrapper-spacing-s 0;

  @include mq(m) {
    width: col(6);
    padding: $spacing * 3 0 0;
  }

  @include mq(l) {
    padding: 0;
  }

  @include mq(xl) {
    width: col(5);

    .eda &,
    .net-modem &,
    .net-gigaprojet &,
    [data-template='blog'] & {
      width: col(6);
    }
  }
}

.section-hero__content__title {
  margin: 0 0 $spacing;
}

.section-hero__content__text {
  margin-bottom: $spacing * 1.5;

  @include mq($until: m) {
    font-size: 1.5rem;
  }

  @include mq(m) {
    margin-bottom: $spacing * 3;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 2.7;
  }
}

.section-hero__content__icon {
  max-width: 14rem;
  margin-bottom: $spacing * 2.4;
}

.section-hero__price-box {
  display: inline-block;
  width: 32rem;
  border-radius: 1.5rem;

  .price-box__misc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price-box__order {
    margin-top: 0;
  }

  .price-box-inner {
    padding: $spacing * 1.5;
  }
}

@include mq(m) {
  .section-hero.reverse {
    .section-hero__inner {
      display: flex;
      flex-direction: row-reverse;
    }

    .speedlines {
      left: 0;
    }
  }
}
