
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        












































.trust {
}

.trust__title,
.trust__text {
  @extend %text-center;
}

.trust__text {
  font-family: $ff-default;
  font-weight: 300;
}

.trust__grid-outer {
  margin-top: $spacing * 2.5;

  @include mq(xl) {
    margin-top: $spacing * 3.5;
  }
}

.trust__grid {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
}

.trust__grid__item {
  flex-basis: 50%;
  padding: $spacing * 2;
  border: 1px solid $c-light-gray;
  border-collapse: collapse;

  @include mq(m) {
    flex-basis: 33%;
  }

  @include mq(xl) {
    flex-basis: 25%;
  }
}
