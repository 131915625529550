
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




















































































.tv-solution-children {
  overflow: hidden;

  ::v-deep {
    .steps__title {
      flex-basis: 50%;
    }

    .services-list__list__item {
      border-bottom: 1px solid $c-light-gray;
    }

    .special-numbers-table {
      padding-right: 0;
      padding-left: 0;

      @include mq($until: xl) {
        overflow-x: scroll;
      }
    }

    .special-numbers-table__table__row__value {
      padding: $spacing;

      &:first-child {
        text-align: left;
      }

      div {
        @extend %text-center;
      }
    }
  }
}

.tv-solution-children__separator {
  width: 80%;
  margin: 0 auto;
}
